@import url(./themes/drawer.scss);
@import url(./icons.scss);
@import url(./themes/menu.scss);
@import url(./themes/toast.scss);
@import url(./themes/tag.scss);

:root {
	--primary-color: #7f56d9;
	--primary-color-light: #7f56d9;
	--gray-color: #344054;
	--gray-color-light: #667085;
	--black-color-light: #1f2937;

	--primary-25-color: 250, 250, 255;
	--primary-50-color: 244, 243, 255;
	--primary-100-color: 235, 233, 254;
	--primary-200-color: 217, 214, 254;
	--primary-300-color: 189, 180, 254;
	--primary-400-color: 155, 138, 251;
	--primary-500-color: 122, 90, 248;
	--primary-600-color: 105, 56, 239;
	--primary-700-color: 89, 37, 220;
	--primary-800-color: 74, 31, 184;
	--primary-900-color: 62, 28, 150;

	--gray-25-color: 252, 252, 253;
	--gray-50-color: 249, 250, 251;
	--gray-100-color: 242, 244, 247;
	--gray-200-color: 234, 236, 240;
	--gray-300-color: 208, 213, 221;
	--gray-400-color: 152, 162, 179;
	--gray-500-color: 102, 112, 133;
	--gray-600-color: 71, 84, 103;
	--gray-700-color: 52, 64, 84;
	--gray-800-color: 29, 41, 57;
	--gray-900-color: 16, 24, 40;

	--error-25-color: 255, 251, 250;
	--error-50-color: 254, 243, 242;
	--error-100-color: 254, 228, 226;
	--error-200-color: 254, 205, 202;
	--error-300-color: 253, 162, 155;
	--error-400-color: 249, 112, 102;
	--error-500-color: 240, 68, 56;
	--error-600-color: 217, 45, 32;
	--error-700-color: 180, 35, 24;
	--error-800-color: 145, 32, 24;
	--error-900-color: 122, 39, 26;

	--warning-25-color: 255, 252, 245;
	--warning-50-color: 255, 250, 235;
	--warning-100-color: 254, 240, 199;
	--warning-200-color: 254, 223, 137;
	--warning-300-color: 254, 200, 75;
	--warning-400-color: 253, 176, 34;
	--warning-500-color: 247, 144, 9;
	--warning-600-color: 220, 104, 3;
	--warning-700-color: 181, 71, 8;
	--warning-800-color: 147, 55, 13;
	--warning-900-color: 122, 46, 14;

	--success-25-color: 246, 254, 249;
	--success-50-color: 236, 253, 243;
	--success-100-color: 209, 250, 223;
	--success-200-color: 166, 244, 197;
	--success-300-color: 108, 233, 166;
	--success-400-color: 50, 213, 131;
	--success-500-color: 18, 183, 106;
	--success-600-color: 3, 152, 85;
	--success-700-color: 2, 122, 72;
	--success-800-color: 5, 96, 58;
	--success-900-color: 5, 79, 49;

	--blue-25-color: 245, 250, 255;
	--blue-50-color: 239, 248, 255;
	--blue-100-color: 209, 233, 255;
	--blue-200-color: 178, 221, 255;
	--blue-300-color: 132, 202, 255;
	--blue-400-color: 83, 177, 253;
	--blue-500-color: 46, 144, 250;
	--blue-600-color: 21, 112, 239;
	--blue-700-color: 23, 92, 211;
	--blue-800-color: 24, 73, 169;
	--blue-900-color: 25, 65, 133;
}

.all-unset {
	all: unset;
}

.color-primary {
	color: var(--primary-color);
}

.color-primary-light {
	color: var(--primary-color-light);
}

.color-gray {
	color: var(--gray-color);
}

.color-gray-light {
	color: var(--primary-color-light);
}

.otpInput::placeholder {
	color: #eee !important;
	opacity: 1;
}

#dob::placeholder {
	color: #667085;
	opacity: 1;
}

.error {
	color: #f04438;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.ant-form-item {
	margin: 0;
	padding: 0;
}

.secondaryButton {
	color: #6941c6;
	background-color: #f9f5ff;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border: 1px solid #f9f5ff;
	border-radius: 8px;
	height: 44px;

	&:hover {
		background-color: #f4ebff;
	}

	&:disabled {
		color: #d6bbfb;
	}
}

.ant-upload.ant-upload-select {
	display: block;
}

.ant-picker {
	padding: 10px 14px;
	height: 44px;

	text-align: left;
	vertical-align: middle;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.ant-form-item-control-input input,
.ant-picker .ant-picker-input > input {
	font-size: 16px;
	line-height: 24px;
}

.ant-form-item .ant-form-item-label {
	padding: 0 0 6px;

	& > label {
		color: #344054;
	}
}

.home-nav-user {
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 0.5px 1px 0px rgba(0, 0, 0, 0.1),
		0px 2px 6px 0px rgba(0, 0, 0, 0.04);
}

.fancy-line {
	position: relative;

	&::after {
		content: url("data:image/svg+xml,%3Csvg width='308' height='24' viewBox='0 0 308 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.06624 16.6758C114.719 8.60653 319.961 -11.18 304.964 21.0579' stroke='url(%23paint0_linear_1868_36845)' stroke-width='4.5' stroke-linecap='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1868_36845' x1='306.496' y1='12.3728' x2='3.52816' y2='13.6405' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237F54E0'/%3E%3Cstop offset='1' stop-color='%23DCCDFE'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
		position: absolute;
		right: 0;
		z-index: -1;
		bottom: -30px;
	}
}

.product-demo-modal .ant-modal-content {
	padding: 0 !important;
}
.product-demo-modal {
	@media (min-width: 768px) {
		width: 761px !important;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.step-shadow-card {
	box-shadow: 0px 0px 0px 3px #fff, 0px 1px 1px 0px rgba(0, 0, 0, 0.06), 0px 2px 8px 0px rgba(0, 0, 0, 0.05),
		0px 4px 20px 0px rgba(0, 0, 0, 0.04);
}

.sessionExpiredPopup {
	max-width: 350px;

	span.session-expired-icon {
		height: 48px;
		aspect-ratio: 1;
		background-size: cover;
		background-image: url("data:image/svg+xml,%3Csvg width='57' height='56' viewBox='0 0 57 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4.5' y='4' width='48' height='48' rx='24' fill='%23F4EBFF'/%3E%3Cpath d='M33 32L38 37M38 32L33 37M28.5 31.5H24C22.6044 31.5 21.9067 31.5 21.3389 31.6722C20.0605 32.06 19.06 33.0605 18.6722 34.3389C18.5 34.9067 18.5 35.6044 18.5 37M31 23.5C31 25.9853 28.9853 28 26.5 28C24.0147 28 22 25.9853 22 23.5C22 21.0147 24.0147 19 26.5 19C28.9853 19 31 21.0147 31 23.5Z' stroke='%237F56D9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect x='4.5' y='4' width='48' height='48' rx='24' stroke='%23F9F5FF' stroke-width='8'/%3E%3C/svg%3E%0A");
	}
}

.play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: none;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.video-playing .play-button {
	display: none;
}

.bg-hero {
	background-image: url(assets/images/bg-matrix.png);
	background-size: cover;
	object-fit: contain;
}

.bg-hero-sm {
	background-image: url(assets/images/bg-ellipse-sm.png);
}

.bg-testimonial {
	@apply relative overflow-hidden after:absolute after:inset-x-0 after:-bottom-96 after:mx-auto after:h-96 after:w-96 after:rounded-full after:shadow-[0_0_800px_0px_#9CA3AF69] after:content-[unset] md:after:content-[''];
}

.bg-separate {
	background: linear-gradient(270deg, rgba(255, 255, 255, 0.43) 0.24%, #dfe2e7 50.12%, rgba(255, 255, 255, 0) 100%);
}

.ant-carousel .slick-list .slick-slide {
	pointer-events: unset;
}
.scrollbar-hidden {
	overflow-y: auto;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
		height: 0;
		width: 0;
	}
}

.no-scroll {
	overflow: hidden;
}

input[type="number"] {
	-moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.hover-fill-white:hover svg path {
	fill: #ffffff;
}

@keyframes blinking-dot {
	from {
		@apply bg-red-600;
	}
	to {
		@apply bg-amber-500;
	}
}

.blinking-dot {
	animation: blinking-dot 1s infinite cubic-bezier(1, 0.01, 0, 0.99);
	@apply h-2 w-2 rounded-full border border-solid border-white bg-red-600 ring-1 ring-gray-200;
}

.toggle-switch {
	display: flex;
	width: auto;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;
	height: 18px;
	border-radius: 25px;
	box-shadow: 0px 0px 0px 1px #fcd34d, 0px 2px 4px 0px rgba(17, 24, 39, 0.13) inset,
		0px 0px 0px 1px rgba(255, 255, 255, 0.75) inset;
}

.store-front {
	box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset, 0px 0px 0px 1px #fbbf24,
		0px 1px 1px 0px rgba(17, 24, 39, 0.12), 0px 2px 4px 0px rgba(17, 24, 39, 0.11);
}

.disabled {
	pointer-events: none;
	opacity: 0.7;
}

.order-create-date-range-picker.ant-picker-dropdown.ant-picker-dropdown {
	.ant-picker-header {
		@apply rounded-md bg-gray-50 px-1 py-0.5 text-gray-950;

		button {
		}
	}

	.ant-picker-body {
		.ant-picker-content {
			.ant-picker-cell:has(.ant-picker-cell-inner-warning) {
				--text-color: theme("colors.orange.600");
				--selected-bg-color: theme("colors.orange.600");
				--selected-hover-text-color: theme("colors.white");
				--selected-hover-bg-color: theme("colors.orange.600");
				--selected-text-color: theme("colors.white");
				--in-range-bg-color: theme("colors.orange.50");
				--in-range-text-color: theme("colors.orange.600");
				--hover-bg-color: theme("colors.orange.50");
				--hover-text-color: var(--text-color);
			}

			.ant-picker-cell.ant-picker-cell:has(.ant-picker-cell-inner-hover-warning) {
				--selected-bg-color: theme("colors.orange.600");
				--selected-text-color: theme("colors.white");
				--selected-hover-text-color: theme("colors.white");
				--selected-hover-bg-color: theme("colors.orange.600");
				--in-range-bg-color: theme("colors.orange.100");
				--in-range-text-color: theme("colors.orange.600");
			}

			.ant-picker-cell:has(.ant-picker-cell-inner-successful) {
				--text-color: theme("colors.emerald.600");
				--selected-bg-color: theme("colors.emerald.600");
				--selected-hover-text-color: theme("colors.white");
				--selected-hover-bg-color: theme("colors.emerald.600");
				--selected-text-color: theme("colors.white");
				--in-range-bg-color: theme("colors.emerald.50");
				--in-range-text-color: theme("colors.emerald.600");
				--hover-bg-color: theme("colors.emerald.50");
				--hover-text-color: var(--text-color);
			}
		}
	}
	// Hiding next year & previous year buttons
	.ant-picker-header-super-prev-btn,
	.ant-picker-header-super-next-btn {
		display: none;
	}

	.ant-picker-header-prev-btn > svg,
	.ant-picker-header-next-btn > svg {
		@apply mx-0;
	}
}

@media (max-width: 768px) {
	.ant-picker-dropdown {
		max-width: 90vw;
	}

	.ant-picker-panel:last-child:not(:first-child) .ant-picker-date-panel {
		display: none !important;
	}

	.ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-next-btn,
	.ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-super-next-btn {
		visibility: initial !important;
	}

	.ant-picker-panel .ant-picker-date-panel{
		width: unset !important;
	}

	.ant-picker-presets {
		display: none !important;
	}
}

