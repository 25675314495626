.terms-list {
	& > ol {
		@apply relative ps-5;

		& > li {
			@apply mb-5;

			&::before {
				content: counters(item, ".") ". " !important;
				@apply absolute right-[calc(100%-1.25rem+4px)] text-lg font-semibold;
			}
		}
	}

	ol {
		counter-reset: item;
		/* Reset counter for top-level <ol> */

		strong {
			@apply text-lg font-semibold;
		}

		& > li {
			display: block;
		}

		/* Target all list items to apply the counter, not just top-level */
		& > li::before {
			content: counters(item, ".") " ";
			/* Use 'counters' for nesting */
			counter-increment: item;
		}
	}

	.terms-list-roman {
		& > li::before {
			content: counter(item, lower-roman) ") " !important;
		}
	}
}
