.ant-notification.ant-notification {
	z-index: 99999;
	&.ant-notification-stack:not(.ant-notification-stack-expanded)
		> .ant-notification-notice-wrapper:nth-last-child(2) {
		& {
			@apply select-none rounded-md border border-gray-500/40 bg-white bg-gradient-to-r from-gray-200 to-white  text-sm text-gray-600 shadow-sm  shadow-gray-200 [--tw-gradient-to-position:85%];
		}

		&:has(.ant-notification-notice-success) {
			@apply border-emerald-500/40 from-emerald-600/20 to-white text-emerald-600;
		}

		&:has(.ant-notification-notice-error) {
			@apply border-rose-500/40 from-rose-600/20 to-white text-rose-600;
		}

		&:has(.ant-notification-notice-warning) {
			@apply border-amber-500/40 from-yellow-600/20 to-white text-amber-600;
		}

		&:has(.ant-notification-notice-info) {
			@apply border-blue-500/40 from-blue-600/20 to-white text-blue-600;
		}
	}
	&.ant-notification-stack:not(.ant-notification-stack-expanded)
		> .ant-notification-notice-wrapper:nth-last-child(3) {
		& {
			@apply select-none rounded-md border border-gray-500/40 bg-white bg-gradient-to-r from-gray-200 to-white  text-sm text-gray-600 shadow-sm  shadow-gray-200 [--tw-gradient-to-position:85%];
		}

		&:has(.ant-notification-notice-success) {
			@apply border-emerald-500/40 from-emerald-600/20 to-white text-emerald-600;
		}

		&:has(.ant-notification-notice-error) {
			@apply border-rose-500/40 from-rose-600/20 to-white text-rose-600;
		}

		&:has(.ant-notification-notice-warning) {
			@apply border-amber-500/40 from-yellow-600/20 to-white text-amber-600;
		}

		&:has(.ant-notification-notice-info) {
			@apply border-blue-500/40 from-blue-600/20 to-white text-blue-600;
		}
	}

	.ant-notification-notice-wrapper {
		transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;

		& {
			@apply select-none rounded-md border border-gray-500/40 bg-gradient-to-r from-gray-200 to-white  text-sm text-gray-600 shadow-sm  shadow-gray-200 [--tw-gradient-to-position:85%];
		}

		&:has(.ant-notification-notice-success) {
			@apply border-emerald-500/40 from-emerald-600/20 to-white text-emerald-600;
		}

		&:has(.ant-notification-notice-error) {
			@apply border-rose-500/40 from-rose-600/20 to-white text-rose-600;
		}

		&:has(.ant-notification-notice-warning) {
			@apply border-amber-500/40 from-yellow-600/20 to-white text-amber-600;
		}

		&:has(.ant-notification-notice-info) {
			@apply border-blue-500/40 from-blue-600/20 to-white text-blue-600;
		}

		.ant-notification-notice.ant-notification-notice {
			@apply p-2.5 opacity-100;

			&.ant-notification-notice-closable {
				@apply pr-8;
			}

			.ant-notification-notice-content {
				@apply flex w-full items-start text-left;

				& > div {
				}
				.ant-notification-notice-icon {
					@apply left-3 top-3 inline-flex;
					> svg {
						@apply size-4;
					}
				}

				.ant-notification-notice-message {
					@apply mb-0 ml-6 text-sm text-current;
				}
				.ant-notification-notice-description {
					@apply ml-7 text-xs text-gray-500;
				}
			}

			.ant-notification-notice-close {
				top: 8px;
				right: 8px;
				& svg {
					@apply size-3;
				}
			}
		}
	}
}

