.ant-menu {
	background: inherit;
	&.ant-menu-root {
		border-inline-end: none !important;
	}

	.ant-menu-item {
		@apply font-medium text-gray-500;

		&.ant-menu-item-selected {
			@apply bg-white;
			box-shadow: 0px 2px 2px 0px rgba(17, 24, 39, 0.06), 0px 1px 0px 0px rgba(17, 24, 39, 0.08);
		}
	}
}

.ant-dropdown {
	.ant-dropdown-menu {
		.ant-dropdown-menu-item {
			@apply font-normal text-gray-500;

			&[data-item-red] {
				@apply text-red-500;
				&:hover {
					@apply bg-red-50;
				}
			}
		}
	}
}
