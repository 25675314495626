.ant-layout-sider.smv-sidebar {
	@apply sticky bottom-0 left-0 top-0 h-screen max-h-screen border-r border-gray-200 bg-gray-100 z-30;
	width: 240px;

	.logo-container {
		@apply px-6 pb-4 pt-4 flex max-h-16 justify-center;
		transition: width 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	ul {
		li {
			transition: border-color 0.3s, background 0.3s, padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
				margin 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
		}
	}

	&.minimized {
		.logo-container {
			max-width: 100%;
			@apply px-2.5;

			.upload-logo {
				@apply truncate;

				.upload-logo-text {
					animation: hide 0.1s ease-in forwards;
				}
			}
		}

		ul {
			li {
				padding-inline: 10px !important;
				margin-inline: auto !important;
				display: flex;
				width: fit-content;

				.ant-menu-title-content {
					display: none;
				}
			}

			&.profile-dropdown li {
				padding-inline: 8px !important;
			}
		}
	}

	&:not(.minimized) {
		.profile-dropdown {
			@apply px-2.5;
		}
	}
}

@keyframes hide {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		display: none;
	}
}

