.upload-cloud {
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;

	&::before {
		position: absolute;
		height: 16px;
		width: 16px;
		left: 0;
		top: 0;
		content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.66634 10.8282C1.86235 10.29 1.33301 9.37347 1.33301 8.33333C1.33301 6.77095 2.52735 5.48753 4.05284 5.34625C4.36488 3.44809 6.01317 2 7.99967 2C9.98618 2 11.6345 3.44809 11.9465 5.34625C13.472 5.48753 14.6663 6.77095 14.6663 8.33333C14.6663 9.37347 14.137 10.29 13.333 10.8282M5.33301 10.6667L7.99967 8M7.99967 8L10.6663 10.6667M7.99967 8L7.99967 14' stroke='%237F56D9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	}
}
